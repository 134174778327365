import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import SubscriptionPlan from "../Components/SubscriptionPlan";

type Props = {
  msisdn: string;
  unSubscribeUser: () => Promise<void>;
  logoutUser: () => void;
  jwtToken: any;
};

const SubscriptionPlanScreen = (props: Props) => {
  const [packageList, setPackageList] = useState<any>([]);
  const [availablePackages, setAvailablePackages] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalCong, setShowModalCong] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    navigate("/home");
  };
  const openModalSuccess = () => setShowModalCong(true);
  const closeModalSuccess = () => {
    setShowModalCong(false);
    navigate("/home");
  };
  useEffect(() => {
    if (props.jwtToken) {
      getPackageList();
    }
  }, [props.jwtToken]);

  const getPackageList = async () => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let endPoint = ApiNames.package_list + "?msisdn=" + msisdn;
      let response = await fetchApiGet(props.jwtToken, endPoint);
      if (response.status === 0) {
        if (!response.existingPackage) {
          openModal();
        }
        const _temp = response.existingPackage?.id;
        console.log(_temp);
        setPackageList(_temp);
        setAvailablePackages(response.availablePackages);
        setIsLoader(false);
      } else {
        openModal();
        setIsLoader(false);
        console.log("Something went Wrong");
      }
    } catch (error) {
      setIsLoader(false);
      console.log("Something went Wrong", error);
    }
  };

  const onHandleChangePackage = async (index: number, packageType: any) => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let _data = {
        msisdn: msisdn,
        packageId: index,
        sub_package: packageType,
      };
      let endPoint = ApiNames.changePackageWeb;
      let response = await fetchApiPost(endPoint, props.jwtToken, _data);
      if (response.status === 0) {
        const date = new Date();
        localStorage.setItem("packageChangeTime", date.toString());
        openModalSuccess();
      } else {
        console.log("Something went Wrong");
      }
    } catch (error) {
      console.log("Something went Wrong", error);
    }
  };

  return (
    <>
      <SubscriptionPlan
        msisdn={props.msisdn}
        unSubscribeUser={props.unSubscribeUser}
        logoutUser={props.logoutUser}
        errorMsg={errorMsg}
        jwtToken={props.jwtToken}
        closeModal={closeModal}
        closeModalSuccess={closeModalSuccess}
        onHandleChangePackage={onHandleChangePackage}
        packageList={packageList}
        availablePackages={availablePackages}
        showModal={showModal}
        showModalCong={showModalCong}
        loader={isLoader}
      />
    </>
  );
};

export default SubscriptionPlanScreen;
