import otplogo from "../Assets/otplogo.webp";
import midiqlogo from "../Assets/midiq logo.webp";
import bottomImg from "../Assets/bottomImg.webp";
import FamilyImg from "../Assets/familyImg.webp";
import FamilyImgMob from "../Assets/familyImgmob.webp";
import FamilyHealthImg from "../Assets/FamilyHealth.webp";
import FamilyHealthMobImg from "../Assets/familyhealthMobile.webp";
import WatchImg from "../Assets/winwatch.webp";
import WatchMobImg from "../Assets/winwatchmob.webp";
import { Spinner } from "react-bootstrap";
import "../StyleSheets/landingstyle.css";
// param based images
// import landingPageThree from "../Assets/landingPageThree.webp";
// import landingthreemob from "../Assets/landingthreemob.webp";
// import landingfour from "../Assets/landingfour.webp";
// import landingfourmob from "../Assets/landingfourmob.webp";
// import landingfive from "../Assets/landingfive.webp";
// import landingfivemob from "../Assets/landingfivemob.webp";
interface Landing {
  isLoading: Boolean;
  msisdn: string;
  errorMsg: string;
  campaignParameter: string | null | undefined;
  onMsisdnChange: (value: string) => void;
  onSendOTPPressed: () => void;
  isModel: boolean;
  onHandleJoinNow: () => void;
  package_id: string | null;
  pricePoint: string;
}

const Landing = (props: Landing) => {
  return (
    <>
      {props.isModel ? (
        <div className="lp-content">
          <div className="c-div">
            <img
              // fetchpriority="high"
              width="137"
              src={midiqlogo}
              alt="logo"
            />
            <div className="title">
              {" "}
              Stay Ahead of Your<span> Health with MedIQ:</span> Connecting You
              to <span> Personalized Care.</span>
            </div>
            <button onClick={() => props.onHandleJoinNow()}>Join Now</button>
          </div>
        </div>
      ) : (
        <div className="PinSection">
          <div className="rightLogo">
            <img src={otplogo} alt="" />
          </div>
          <div className="bottomImg">
            <img src={bottomImg} alt="" />
          </div>
          <div className="PinBg"></div>
          <div className="mobileimg">
            <img
              src={
                props.campaignParameter === "family"
                  ? FamilyHealthMobImg
                  : props.campaignParameter === "smartwatch"
                  ? WatchMobImg
                  : FamilyImgMob
              }
              alt=""
            />
          </div>
          <div className="faimlybg">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="formWraper">
                    <div className="healthText">
                      <p>
                        Get <span>Unlimited</span> Access to
                      </p>
                      <p>
                        <span>Health, Fitness, and Beauty Tips</span>
                      </p>
                      <p className="inputnumber">
                        Please enter a 10-digit mobile number.
                      </p>
                    </div>

                    <div className="otpinput-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">+92</span>
                      </div>
                      <input
                        type="tel"
                        autoComplete="off"
                        className="telinput"
                        placeholder="3xxxxxxxxx"
                        maxLength={10}
                        value={props.msisdn}
                        onChange={(val) =>
                          props.onMsisdnChange(val.target.value)
                        }
                      />
                    </div>
                    <div className="sendPin">
                      {props.isLoading ? (
                        <button>
                          <Spinner></Spinner>
                        </button>
                      ) : (
                        <button onClick={props.onSendOTPPressed}>
                          SEND PIN
                        </button>
                      )}

                      {props.errorMsg && (
                        <div className="errowMsg">*{props.errorMsg}*</div>
                      )}
                    </div>
                    <div className="resend">
                      {/* <p>
                    Didn't receive PIN? <span id="resendBtn">Re-send</span>
                  </p> */}
                      <h3 className="note">
                        *Note: Daily Recurring Charges Rs.{props.pricePoint}+T/D
                        and Monthly charges of Rs. 150+T/Month
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              src={
                props.campaignParameter === "family"
                  ? FamilyHealthImg
                  : props.campaignParameter === "smartwatch"
                  ? WatchImg
                  : FamilyImg
              }
              alt=""
              className="absoluteImg"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
