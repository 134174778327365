import React from "react";
import "../StyleSheets/SubscriptionPlan.css";
// import sublogo from '../Assets/midiq logo.webp';
import subheart from "../Assets/img/sub-heart.webp";
import cardbg from "../Assets/img/card-bg.webp";
import cardbgdefault from "../Assets/img/card-bg-default.webp";
import warning from "../Assets/Warning.png";
import congration from "../Assets/congraution.png";
import { Modal } from "react-bootstrap";
// import subtwoheart from '../Assets/img/subtwo-heart.webp'

type Props = {
  msisdn: string;
  unSubscribeUser: () => Promise<void>;
  logoutUser: () => void;
  errorMsg: string;
  jwtToken: string | null;
  closeModal: () => void;
  packageList: any;
  availablePackages: any;
  showModal: boolean;
  showModalCong: boolean;
  closeModalSuccess: () => void;
  onHandleChangePackage: (index: number, packageType: any) => Promise<void>;
  loader: boolean;
};
type ProcessProps = {
  showModal: boolean;
  closeModal: () => void;
};
type ConfigrationProps = {
  showModalCong: boolean;
  closeModalSuccess: () => void;
};
const ConfigrationModal = (props: ConfigrationProps) => {
  return (
    <>
      <Modal
        show={props.showModalCong}
        onHide={props.closeModalSuccess}
        centered
        className="unsub congrat"
      >
        <Modal.Header
          closeButton
          onClick={props.closeModalSuccess}
        ></Modal.Header>
        <Modal.Body>
          <img src={congration} alt="" className="checkimg" />

          <h2>Congratulation</h2>

          <p className="para-text">
            You've successfully purchased a subscription plan.
          </p>
          <button className="btn-continue" onClick={props.closeModalSuccess}>
            Continue
          </button>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const ProcessPopUp = (props: ProcessProps) => {
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.closeModal}
        centered
        className="cs-Modal sp-success unsub"
      >
        <Modal.Header closeButton onClick={props.closeModal}></Modal.Header>
        <Modal.Body>
          <img src={warning} alt="" className="checkimg" />

          <p className="para-text">Your request is in proccess</p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const SubscriptionPlan = (props: Props) => {
  return (
    <>
      <ProcessPopUp showModal={props.showModal} closeModal={props.closeModal} />
      <ConfigrationModal
        showModalCong={props.showModalCong}
        closeModalSuccess={props.closeModalSuccess}
      />
      <div className="subscription">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sub-text">
                <div className="sub-title">
                  <span className="text-one">Subscription</span>{" "}
                  <span className="text-two">Plans</span>
                </div>

                <div className="sub-desc">
                  MediQ offers a comprehensive suite of health and wellness
                  services designed to empower you to take charge of your
                  well-being. We provide unique subscription plans to cater to
                  your specific needs:
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-content">
          <div className="container">
            <div className="row">
              {props.availablePackages?.map((data: any) => (
                <div className="col-xs-12 col-md-3">
                  <div
                    className={
                      props.packageList === data.id
                        ? "d-default active"
                        : "d-default"
                    }
                  >
                    <div className="card-bg">
                      <img src={cardbgdefault} alt="" />
                    </div>
                    <div className="image">
                      <img src={subheart} alt="" />
                    </div>

                    <div className="card-title">{data.title}</div>

                    <div className="card-des">{data.desc}</div>

                    <div className="card-price">{data.priceText}</div>
                    <div className="card-btn">
                      {props.packageList === data.id ? (
                        <button>Subscribed</button>
                      ) : (
                        <button
                          onClick={() =>
                            props.onHandleChangePackage(
                              data.id,
                              data.packageType
                            )
                          }
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlan;
