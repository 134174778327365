import React, { useState, useEffect } from "react";
import "../StyleSheets/jazzworld.css";
import Logo from "../Assets/img/jazz-world/mediq_logo_1.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Navigation, Pagination } from "swiper/modules";
import "swiper/css/bundle";
import Card1 from "../Assets/img/jazz-world/card1.webp";
import Arrow from "../Assets/img/jazz-world/book-now-icon.svg";
import Card2 from "../Assets/img/jazz-world/card2.webp";
import Card3 from "../Assets/img/jazz-world/card3.webp";
import Card4 from "../Assets/img/jazz-world/card4.webp";
import Icon1 from "../Assets/img/jazz-world/icon1.webp";
import Icon2 from "../Assets/img/jazz-world/icon2.webp";
import Icon3 from "../Assets/img/jazz-world/icon3.webp";
import Icon4 from "../Assets/img/jazz-world/icon4.webp";
import Icon5 from "../Assets/img/jazz-world/icon5.webp";
import Icon6 from "../Assets/img/jazz-world/icon6.webp";
import Icon7 from "../Assets/img/jazz-world/icon7.webp";
import Info1 from "../Assets/img/jazz-world/info1.webp";
import Info2 from "../Assets/img/jazz-world/info2.webp";
import Info3 from "../Assets/img/jazz-world/info3.webp";
import Info4 from "../Assets/img/jazz-world/info4.webp";
import Youtube from "../Assets/img/jazz-world/Frame.svg";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Diet1 from "../Assets/img/jazz-world/diet1.webp";
import Diet2 from "../Assets/img/jazz-world/diet2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

type Props = {
  inputRef: React.RefObject<HTMLInputElement>[];
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleOtpInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  isActiveUser: boolean;

  msisdn: string;
  show: boolean;
  handleClose: () => void;
  handleShow: () => void;
  seconds: number;
  onResendOTPPressed: () => void;
  subscribe: () => Promise<void>;
  isError: boolean;
  errorMessage: string;
  onUnSubscribe: () => Promise<void>;
  isLoading: boolean;
  encodeMsisdn: string;
  handleExternalNavigation: () => void;
};

const JazzWorld = (props: Props) => {
  console.log(props.encodeMsisdn);
  return (
    <>
      <div className="popup-section">
        {/* modal */}
        <Modal
          className="jazz-world-popup"
          centered
          show={props.show}
          onHide={props.handleClose}
        >
          <div className="jazz-world-content">
            <div className="jazz-world-heading">
              Get Unlimited Access to Health Care
            </div>
            <div className="jazz-world-popup-form">
              <div className="text">Enter your mobile number.</div>
              <div className="jazz-world-popup-input">
                <span>+92</span>
                <input
                  type="tel"
                  placeholder="xxx - xxxxxxx"
                  value={props.msisdn}
                  readOnly={true}
                />
              </div>

              <div className="jazz-world-popup-form otp-form">
                <div className="text">Enter the PIN sent via SMS to verify</div>
                <div className="jazz-world-popup-input">
                  <input
                    type="tel"
                    name="one"
                    maxLength={1}
                    ref={props.inputRef[0]}
                    value={props.otp.one}
                    onChange={(e) => props.handleOtpInput(e, 0)}
                    onKeyDown={(e) => props.handleBackspace(e, 0)}
                    autoFocus
                  />
                  <input
                    type="tel"
                    name="two"
                    maxLength={1}
                    ref={props.inputRef[1]}
                    value={props.otp.two}
                    onChange={(e) => props.handleOtpInput(e, 1)}
                    onKeyDown={(e) => props.handleBackspace(e, 1)}
                  />
                  <input
                    type="tel"
                    name="three"
                    maxLength={1}
                    ref={props.inputRef[2]}
                    value={props.otp.three}
                    onChange={(e) => props.handleOtpInput(e, 2)}
                    onKeyDown={(e) => props.handleBackspace(e, 2)}
                  />
                  <input
                    type="tel"
                    name="four"
                    maxLength={1}
                    ref={props.inputRef[3]}
                    value={props.otp.four}
                    onChange={(e) => props.handleOtpInput(e, 3)}
                    onKeyDown={(e) => props.handleBackspace(e, 3)}
                  />
                </div>
              </div>
              {props.isError ? (
                <p className="error">{props.errorMessage}</p>
              ) : (
                <></>
              )}
              <div className="resend">
                {props.seconds > 0 && (
                  <div className="show-count">
                    <span> 00:{props.seconds}</span>
                  </div>
                )}
                {props.seconds <= 0 && (
                  // <p>
                  //   Didn't receive a PIN?{" "}
                  //   <span id="resendBtn" onClick={() => onResendOTPPressed()}>
                  //     Re-send
                  //   </span>
                  // </p>
                  <div className="charges-text">
                    <div className="resend-code">
                      <div className="timer-text">
                        Didn’t receive a code?{" "}
                        <span
                          id="resendBtn"
                          onClick={() => props.onResendOTPPressed()}
                        >
                          Resend Code
                        </span>
                      </div>

                      <div className="not-received"></div>
                    </div>
                  </div>
                )}
              </div>

              {props.isLoading ? (
                <>
                  {" "}
                  <button>Proceed.....</button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    onClick={() => {
                      props.subscribe();
                    }}
                  >
                    Proceed
                  </button>
                </>
              )}

              {/* <div className="charges-text">
                <div className="resend-code">
                  <div className="timer-text">
                    Didn’t receive a code?<span> Resend Code</span>
                  </div>

                  <div className="not-received"></div>
                </div>
              </div> */}

              <div className="charges-text">
                *Note: Daily Recurring Charges Rs.5+T/D
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div className="jazzworld-wraper">
        <div className="header">
          <div className="container">
            <div className="main-header">
              <div className="logo">
                <img src={Logo} alt="mediq-logo" />
              </div>
              <div className="buttons">
                {props.isActiveUser ? (
                  <>
                    <a href="#" onClick={props.onUnSubscribe}>
                      Unsubscribe
                    </a>
                  </>
                ) : (
                  <>
                    <a href="#" onClick={props.handleShow}>
                      Subscribe
                    </a>
                  </>
                )}

                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.homemedics.app&hl=en"
                >
                  Get App!
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="discounted-swiper jw-main-padding">
          <div className="container">
            <div className="discounted-swiper-wraper">
              <Swiper
                slidesPerView={2.3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.2,
                  },
                  1024: {
                    slidesPerView: 2.3,
                  },
                }}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="discounted-swiper-card pink-card"
                    onClick={() => {
                      if (props.isActiveUser) {
                        props.handleExternalNavigation();
                      } else {
                        props.handleShow();
                      }
                    }}
                  >
                    <div className="discounted-text">
                      <p>
                        Get <span>Free</span>
                      </p>
                      <h3>
                        Doctor Consultation <br />
                        Today!
                      </h3>
                      <div className="book-now pink-btn">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            if (props.isActiveUser) {
                              props.handleExternalNavigation();
                            } else {
                              props.handleShow();
                            }
                          }}
                        >
                          Book Now
                          <img src={Arrow} alt="arrow" />
                        </a>
                      </div>
                    </div>
                    <img src={Card1} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="discounted-swiper-card green-card"
                    onClick={() => {
                      if (props.isActiveUser) {
                        props.handleExternalNavigation();
                      } else {
                        props.handleShow();
                      }
                    }}
                  >
                    <div className="discounted-text">
                      <p>
                        <span> BIG 50%</span> Off
                      </p>
                      <h3>
                        On Specialist
                        <br /> Consultations
                      </h3>
                      <div className="book-now green-btn">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            if (props.isActiveUser) {
                              props.handleExternalNavigation();
                            } else {
                              props.handleShow();
                            }
                          }}
                        >
                          Book Now
                          <img src={Arrow} alt="arrow" />
                        </a>
                      </div>
                    </div>
                    <img src={Card2} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="discounted-swiper-card red-card"
                    onClick={() => {
                      if (props.isActiveUser) {
                        props.handleExternalNavigation();
                      } else {
                        props.handleShow();
                      }
                    }}
                  >
                    <div className="discounted-text">
                      <p>
                        <span className="twentydisc"> 20%</span> Disc.
                      </p>
                      <h3>On Home Lab Tests</h3>
                      <div className="book-now red-btn">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            if (props.isActiveUser) {
                              props.handleExternalNavigation();
                            } else {
                              props.handleShow();
                            }
                          }}
                        >
                          Book Now
                          <img src={Arrow} alt="arrow" />
                        </a>
                      </div>
                    </div>
                    <img src={Card3} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="discounted-swiper-card door-step"
                    onClick={() => {
                      if (props.isActiveUser) {
                        props.handleExternalNavigation();
                      } else {
                        props.handleShow();
                      }
                    }}
                  >
                    <div className="discounted-text">
                      <p>
                        Get medicine <br /> delivered to your
                      </p>
                      <h3>Door Step</h3>
                      <div className="book-now door-btn">
                        <a
                          href="javascript:;"
                          onClick={() => {
                            if (props.isActiveUser) {
                              props.handleExternalNavigation();
                            } else {
                              props.handleShow();
                            }
                          }}
                        >
                          Order Now
                          <img src={Arrow} alt="arrow" />
                        </a>
                      </div>
                    </div>
                    <img src={Card4} alt="doctor" className="abs-image" />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="available-packages">
          <div className="container">
            <div className="">
              <h2 className="main-heading">Available Package</h2>
            </div>
            <div className="swiper-slider section-inner-margin">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 2.1,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="discounted-packages blue-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        50% <span>Off</span>
                      </div>
                      <p>
                        On Video
                        <br /> Consultation
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages pink-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        50% <span>Off</span>
                      </div>
                      <p>
                        On Message <br /> Consultation
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages green-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        20% <span>Off</span>
                      </div>
                      <p>
                        On Laboratory <br /> Service
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="discounted-packages purple-card">
                    <div className="discount-text">
                      <div className="fiftyperoff">
                        08% <span>Off</span>
                      </div>
                      <p>
                        On Pharmacy <br /> Service
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="feature-service jw-main-padding">
          <div className="container">
            <div className="feature-text">
              <h3 className="main-heading">Feature Services</h3>
            </div>
            <div className="swiper-services section-inner-margin">
              <Swiper
                slidesPerView={6}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode]}
                breakpoints={{
                  340: {
                    slidesPerView: 3.5,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 10,
                  },
                }}
                className="mySwiper service"
              >
                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon1} alt="health" />
                    </div>
                    <h3>General Practitioners</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon2} alt="health" />
                    </div>

                    <h3>Specialist Doctors</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon3} alt="health" />
                    </div>
                    <h3>Paperless Claims</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon4} alt="health" />
                    </div>
                    <h3>Video on Demand</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon5} alt="health" />
                    </div>
                    <h3>Health Tips</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon6} alt="health" />
                    </div>
                    <h3>Lab Services</h3>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="services-packages">
                    <div className="ser-img">
                      <img src={Icon7} alt="health" />
                    </div>
                    <h3>Pharmacy Services</h3>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="information">
          <div className="container">
            <div className="">
              <h4 className="main-heading">Authentic Health Information</h4>
            </div>

            <div className="slider-info  section-inner-margin">
              <Swiper
                slidesPerView={3.3}
                spaceBetween={30}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                  // clickable: true,
                }}
                pagination={false}
                modules={[FreeMode, Navigation, Pagination]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3.3,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info1} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        Effective Exercises and Tips with Dr. Burhan Rabi Today
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info2} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        Top 10 Signs and Symptoms Explained by Miss. Anum Khalid
                      </p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info3} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>Impact on Mothers & Baby Defeating Anemia</p>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="health-info">
                    <div className="info-img">
                      <img src={Info4} alt="health" />
                    </div>
                    <div className="icon-text">
                      <img src={Youtube} alt="health" />
                      <p>
                        How to Fade Discolored Spots and Achieve Clearer Skin
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="swiper-btn-flex">
                <div className="swiper-button-prev" style={{ color: "#fff" }}>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.20942 12.2056L0.106636 6.10278L6.20942 0L7.20615 0.996729L2.78207 5.40332H13.886V6.80224H2.78207L7.20615 11.2088L6.20942 12.2056Z" />
                  </svg>
                </div>
                <div className="show-all-button">
                  <a target="_blank" href=" https://mediq.com.pk/video/">
                    Show All
                  </a>
                </div>
                <div className="swiper-button-next" style={{ color: "#fff" }}>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.79058 12.2056L13.8934 6.10278L7.79058 0L6.79385 0.996729L11.2179 5.40332H0.114014V6.80224H11.2179L6.79385 11.2088L7.79058 12.2056Z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="health-tips jw-main-padding">
          <div className="container">
            <div className="health-text">
              <h2 className="main-heading">Health Tips</h2>
              <a
                target="_blank"
                href="https://mediq.com.pk/mediq-healthcareblogs/"
              >
                View All
              </a>
            </div>

            <div className="slider-health section-inner-margin">
              <Swiper
                slidesPerView={2.2}
                spaceBetween={30}
                navigation={false}
                pagination={true}
                modules={[FreeMode, Navigation, Pagination]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 30,
                  },
                }}
                className="mySwipe"
              >
                <SwiperSlide>
                  <div className="main-diet bg-red">
                    <div className="slider-text">
                      <p>
                        No Dieting, Do this One Step for Weight Loss and
                        Wellness
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/5-mental-health-benefits-of-athletics/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet1} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="main-diet bg-yellow">
                    <div className="slider-text">
                      <p>
                        Attention Deficit Hypersensitivity Disorder – Overview
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/attention-deficit-hypersensitivity-disorder-overview/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet2} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="main-diet bg-blue">
                    <div className="slider-text">
                      <p>
                        <p>
                          Mental Health and <br /> Well-Being
                        </p>
                      </p>
                      <a
                        target="_blank"
                        href="https://mediq.com.pk/2023/06/02/mental-health-and-well-being/"
                        className="readmorebtn"
                      >
                        Read more{" "}
                        <span>
                          <img src={Arrow} alt="health" />
                        </span>
                      </a>
                    </div>
                    <div className="image">
                      <img src={Diet1} alt="health" />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <footer className="footer jazz-world-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="footer-link">
                  <div className="foo-heading">Social</div>

                  <div className="social-link">
                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/c/mediqsmarthealthcare"
                        className="f-link"
                      >
                        <span> Youtube</span>{" "}
                        <FontAwesomeIcon icon={faYoutube} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/mediq_smart_healthcare/"
                        className="f-link"
                      >
                        {" "}
                        <span>Instagram</span>{" "}
                        <FontAwesomeIcon icon={faInstagram} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://twitter.com/ShcMediq"
                        className="f-link"
                      >
                        {" "}
                        <span> Twitter</span>{" "}
                        <FontAwesomeIcon icon={faTwitter} />{" "}
                      </a>
                    </div>

                    <div className="icon">
                      <a
                        target="_blank"
                        href="https://web.facebook.com/mediq.smarthealthcare"
                        className="f-link"
                      >
                        {" "}
                        <span> Facebook</span>{" "}
                        <FontAwesomeIcon icon={faFacebook} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xs-12">
                <div className="footer-link">
                  <div className="foo-heading">Contact Us</div>

                  <div className="contact-text">
                    <p>UAN: 051 111 377 377 </p>
                    <p>Phone: 051 877 3377 </p>
                    <p>
                      Web:{" "}
                      <a target="_blank" href="#" className="mediqhref">
                        www.mediq.com.pk
                      </a>
                    </p>
                  </div>
                  <div className="footer-text">
                    © 2024. All rights reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default JazzWorld;
