import { useState, useEffect } from "react";
import ApiNames from "../Constants/ApiNames";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import { useSearchParams, useNavigate } from "react-router-dom";
import Landing from "../Components/Landing";

interface ILandingScreen {
  token: string | null;
}

let priceArr = ["5", "8", "10", "15"];
const url = new URL(window.location.href);
const searchParams = new URLSearchParams(url.search);
let qsPackageId: string | null = searchParams.get("package_id")
  ? searchParams.get("package_id")
  : "2";

const LandingScreen = (props: ILandingScreen) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";

  const [msisdn, setMsisdn] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModel, setIsModel] = useState(true);
  const [campaignParameter, setCampaignParameter] = useState(
    searchParams.get("parameter")
      ? searchParams.get("parameter")?.toLocaleLowerCase()
      : searchParams.get("Parameter")
      ? searchParams.get("Parameter")?.toLocaleLowerCase()
      : ""
  );
  const [pricePoint, setPricePoint] = useState("");

  const queryParams = searchParams;
  const perameterP = getParameterCaseInsensitive("p");

  useEffect(() => {
    let pasredPackageId = parseInt(qsPackageId as string);
    if (
      Number.isNaN(pasredPackageId) ||
      pasredPackageId > 4 ||
      pasredPackageId <= 1
    ) {
      qsPackageId = "2";
    }

    setPricePoint(priceArr[parseInt(qsPackageId as string) - 1]);

    if (props.token) {
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.getFlow}?utm_source=${utm_source}&network=jazz&packageId=${qsPackageId}`
      );
      // let response = data;
      if (response.status === 0) {
        if (response.result.isSpecialFlow) {
          navigate("/pin", {
            state: {
              isSpecialFlow: response.result.isSpecialFlow,
              isFastFlow: response.result.fastFlow,
              msisdn: response.result.msisdn,
              otp: response.result.otp,
              milliseconds: response.result.milliseconds,
              utm_source: utm_source,
              campaignParameter: campaignParameter,
              qsPackageId: qsPackageId,
              pricePoint: pricePoint,
              package: response.result.subPackage,
            },
          });
        } else {
          if (response.result.msisdn) {
            if (perameterP === "1") {
              console.log("in");
              setTimeout(() => {
                setIsModel(false);
              }, 2000);
            }
            setMsisdn(response.result.msisdn);
          }
        }
      }
    } catch (error) {
      console.log("get flow error ::", error);
    }
  };

  const onMsisdnChange = (value: string) => {
    if (value === "0" || !value.startsWith("3")) {
      setMsisdn("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setMsisdn(value);
      }
    }
  };

  const onSendOTPPressed = () => {
    if (msisdn.length === 10) {
      setErrorMsg("");
      checkOperator();
    } else {
      setErrorMsg("Please enter a 10 digits mobile number. e.g 3xxxxxxxxx");
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.checkOperator}?msisdn=${msisdn}`
      );
      if (response.status === 0) {
        if (response.network.toLowerCase() === "mobilink") {
          sendOTP(response.package);
        } else {
          setIsLoading(false);
          setErrorMsg("This service is only for Jazz users.");
        }
      } else {
        setIsLoading(false);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const sendOTP = async (_package: string) => {
    try {
      let data = {
        msisdn: msisdn,
        packageId: _package.toLowerCase() === "post" ? 5 : qsPackageId,
      };
      let response = await fetchApiPost(ApiNames.sendOtp, props.token, data);
      if (response.status === 0) {
        navigate("/pin", {
          state: {
            msisdn: msisdn,
            utm_source: utm_source,
            campaignParameter: campaignParameter,
            qsPackageId: qsPackageId,
            pricePoint: pricePoint,
            package: _package,
          },
        });
      } else {
        setIsLoading(false);
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  function getParameterCaseInsensitive(name: String) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null;
  }

  const onHandleJoinNow = () => {
    setIsModel(false);
  };

  return (
    <Landing
      isLoading={isLoading}
      msisdn={msisdn}
      errorMsg={errorMsg}
      campaignParameter={campaignParameter}
      onMsisdnChange={onMsisdnChange}
      onSendOTPPressed={onSendOTPPressed}
      // isModel={isModel}
      isModel={false}
      onHandleJoinNow={onHandleJoinNow}
      package_id={qsPackageId}
      pricePoint={pricePoint}
    />
  );
};

export default LandingScreen;
