import React from "react";
import MainImage from "../Assets/img/beauty/beauty-content.webp";
import CharacterImage from "../Assets/img/beauty/beauty-img.webp";
import "../StyleSheets/beauty.css";
const Beauty = () => {
  return (
    <>
      <div className="beauty-page">
        <div className="image">
          <img
            src={MainImage}
            width={410}
            height={380}
            alt="Beauty main image"
          />
          <div className="l-btn">
            <a href="https://mediq.com.pk/video/" target="_blank">Learn More</a>
          </div>
          <img
            src={CharacterImage}
            width={410}
            height={385}
            alt="Characters"
          />
        </div>
      </div>
    </>
  );
};

export default Beauty;
