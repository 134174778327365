const baseUrl: string | undefined = process.env.REACT_APP_BASEURL;
const baseUrlConversion = () => {
  if (window.location.protocol === "http:") {
    let newBaseUrl: string | undefined = baseUrl;
    newBaseUrl = newBaseUrl?.replace("https", "http");
    return newBaseUrl;
  } else {
    return baseUrl;
  }
  // return "/";
};

export const fetchApiGet = async (token: string | null, endPoint: string) => {
  try {
    // console.warn("fetchApiGet::", `${baseUrlConversion()}${endPoint}`);
    let response = await fetch(`${baseUrlConversion()}${endPoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? "Bearer " + token : "",
      },
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    console.log("fetchApiGet error ::", error);
  }
};

export const fetchApiPost = async (
  endPoint: string,
  token: string | null,
  data: any
) => {
  // console.warn("fetchApiPost::", `${baseUrlConversion()}${endPoint}`);

  let response = await fetch(`${baseUrlConversion()}${endPoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  let jsonResponse = await response.json();

  return jsonResponse;
};
