import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
interface PopUp {
  subPopUp: boolean;
  message: string;
  closePopUp: () => void;
}

const PopUp = (props: PopUp) => {
  return (
    <div>
      <Modal
        backdrop={true}
        show={props.subPopUp}
        onHide={props.closePopUp}
        centered
      >
        <Modal.Body>
          <div className="main-container-alert-modal">
            <div onClick={props.closePopUp} className="custom-cross">
              <FontAwesomeIcon className="custom-x-icon" icon={faXmark} />
            </div>

            {/* <div className="annoucment-text">{props.disclaimerText}</div>
             */}
            <div style={{ textAlign: "center" }}>{props.message}</div>

            {/* <div
          onClick={props.onDisclaimerPressed}
          className="bottom-ok-btn-container "
        >
          <div className="bottom-ok-btn">OK</div>
        </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PopUp;
