import React, { useEffect, useState } from "react";
import Home from "../Components/Home";
import { decode } from "base-64";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";

interface HomeScreen {
  token: string | null;

  removeMsisdn: () => void;
}
const HomeScreen = (props: HomeScreen) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [subPopUp, setSubPopUp] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = [
    "Corporate Employer",
    "Insurance Company",
    "Telecom Operator",
    "Wellness Partners",
  ];
  const [tabIndex, SetTabIndex] = useState(0);

  const closePopUp = () => {
    setSubPopUp(false);
  };

  const onClickTabIndex = (index: number) => {
    if (tabIndex !== index) {
      SetTabIndex(index);
    }
  };

  return (
    <Home
      subscriptionStatus={subscriptionStatus}
      tabs={tabs}
      tabIndex={tabIndex}
      subPopUp={subPopUp}
      message={message}
      onClickTabIndex={onClickTabIndex}
      closePopUp={closePopUp}
    />
  );
};

export default HomeScreen;
